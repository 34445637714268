// Ripple
// --------------------------------------------------

// $ripple-orange: #ff5c35; //#f15d3b; //#ff5c35
// $ripple-teal: #517f70; // green
// $ripple-green: $ripple-teal;
// $ripple-yellow: #ffb000;
// $ripple-blue: #13bfd6; // #08bed5;
// $ripple-blue-green: #0073aa; // #008bad; //
// $ripple-black: #333;
// $ripple-black: #111827;

// $ripple-primary: $ripple-teal;
// $ripple-secondary: $ripple-orange;
// $ripple-tertiary: $ripple-blue-green;
// $ripple-highlight: $ripple-yellow;


// **** NEW
$font-ripple-headings: $font-poppins;
$font-ripple-text: $font-inter;

// New Colours 2023
$ripple-orange: #f87449;
$ripple-black: #000909;
$ripple-white: #f5f3ee;
$ripple-white-lighter: mix($ripple-white, #fff, 25%);
$ripple-blue: #1dbfd5;
$ripple-blue-green: #148392;
$ripple-blue-light: #99e7f1;
$ripple-yellow: #ffb000; // Keep around
$ripple-green: #517f70;

$ripple-primary: $ripple-blue-green;
$ripple-secondary: $ripple-orange;
$ripple-unflagged-color: white;
$ripple-flagged-color: $ripple-yellow;
$ripple-unflagged-color: white;
$ripple-flagged-color: $ripple-yellow;
// $default-color: darken($ripple-teal, 15%);
// *** END NEW

// $ripple-theme-colors: ();
// $ripple-theme-colors: map-merge(
//   (
//     "primary": $ripple-primary,
//     // teal
//     "secondary": $ripple-secondary,
//     //red
//     "highlight": $ripple-yellow,
//     // yellow
//     "tertiary": $ripple-blue-green,
//     "default": $default-color,
//     "yellow": $ripple-yellow,
//     "red": $ripple-orange,
//     "blue": $ripple-blue,
//     "blue-dark": $ripple-blue-green,
//     "teal": $teal,
//     "gray": $ripple-black,
//     //$gray-dark,
//     "white": $white,
//   ),
//   $ripple-theme-colors
// );

$ripple-theme-colors: (
  "blue": $ripple-blue-green,
  "red": $ripple-orange,
  "primary": $ripple-primary,
  "secondary": $ripple-secondary,
  "light": $ripple-white,
  "blue-med": $ripple-blue,
  "blue-light": $ripple-blue-light,
  "text": $ripple-black,
);


// Button themes
$ripple-button-colors: ();
$ripple-button-colors: map-merge(
  (
    "primary": $ripple-blue,
    // $ripple-blue,
    "info": darken($gray, 15%),
    "red": $ripple-orange,
    "blue": $ripple-blue,
    "yellow": $ripple-yellow,
  ),
  $ripple-button-colors
);

.page-ripple-admin {
  $color: "";
  $value: "";

  font-family: $font-ripple-text;
  font-weight: 400;
  color: $ripple-black;
  $navbar-primary-height: 100px;
  background-color: $ripple-white-lighter;
  padding-top: 110px !important;//$header-height + 25px !important;
  position: relative;

  //Ripple Colors - build background/text/link colours from your theme-colors() variable
  @if variable-exists(ripple-theme-colors) {
    @each $color, $value in $ripple-theme-colors {
      .bg-#{$color} {
        background-color: $value !important;
      }
      .border-#{$color} {
        border-color: $value !important;
      }
      .text-#{$color} {
        color: $value !important;
      }
      a.link-#{$color},
      .link-#{$color} a:not(.btn) {
        color: $value !important;
      }
    }
  }

  .h1,
  h1,
  .h2,
  h2,
  h3,
  h4 {
    font-family: $font-ripple-headings !important;
    font-weight: 700;
  }

  h5 {
    font-family: $font-ripple-headings !important;
    font-size: 1em; font-weight: 700;
  }

  #header #navbar {
    height: $header-height !important;
  }

  .font-cursive {
    font-family: $font-ripple-headings !important;
  }

  .bg-gray-light {
    background-color: $gray-100;
  }

  // Buttons
  @if variable-exists(ripple-button-colors) {
    @each $color, $value in $ripple-button-colors {
      .btn-#{$color} {
        background-color: $value;
        border-color: $value;
        color: $white !important;
        &:hover,
        &:focus {
          background-color: darken($value, 10%);
        }
      }
    }
  }

  .layout-subheader {
    top: $header-height;
    background-color: darken($ripple-blue-green, 2%);

    // @include media-breakpoint-up(lg) {
    //   top: $header-height-desktop;
    // }

    //background-color: darken($ripple-teal, 10%);

    .btn-logout {
      background-color: transparent;
      border-radius: 0;
      //   padding-right: 0;
      border: none;
      &:hover,
      &:focus {
        border: none !important;
        box-shadow: none !important;
        background-color: darken($ripple-blue, 5%);
      }
    }

    .nav-pills .nav-link {
      border-radius: 0;
      font-family: $font-ripple-headings;
      font-weight: 600;
      &:hover,
      &:focus {
        background-color: darken($ripple-blue-green, 5%);
      }
    }

    .nav-pills .nav-link.active {
      background-color: $ripple-blue; // rgba($ripple-blue, 0.3);
      color: white !important;
      // color: $ripple-yellow !important;
    }
    .nav .nav-link {
    //  text-transform: uppercase;
    //  font-weight: bold;
      font-size: $font-size-small;
    }
    @include media-breakpoint-down(sm) {
      .container {
        max-width: none;
      }
    }
  }

  .alert,
  .alert-success {
    background-color: $white;
    border-color: $ripple-orange;
    border-width: 3px;
    color: $ripple-black;
    font-weight: bold;
    background-color: white;
    font-family: $font-ripple-text;
    font-size: 1em; //$h4-font-size;
    .close {
      color: $ripple-orange !important;
    }
  }


  #footer {
    background-color: $ripple-black !important;
  }

  h1 {
    font-family: $font-ripple-headings;
    font-weight: 700;
    color: $ripple-black;
  }

  .h2,
  h2 {
    font-weight: 700;
    font-size: $h2-font-size;
    font-family: $font-ripple-headings;
    letter-spacing: 0;
    color: $ripple-black;
    a:not(.btn) {
      color: $ripple-black;
    }
  }

  h3,
  .h3 {
    font-family: $font-ripple-headings;
    font-size: $h3-font-size;
    color: $ripple-black;
    font-weight: 600;
    letter-spacing: 0;
  }

  #header {
    .navbar-brand {
      img {
        border-radius: 6px;
        width: 55px;
        box-shadow: 0 0 0 1px rgba(black, 0.01);
      }
      span.portal {
        color: lighten($ripple-blue, 30%);
      }
    }
  }

  .layout-featured {
    border-bottom: 4px solid $ripple-black; //$ripple-primary;
    background-color: $ripple-white;
    padding-bottom: 40px;
  }

  .school-header {
 //   border-bottom: 1px solid $gray-200; //$ripple-primary;
    .navbar .btn.not-selected {
      background-color: white;
      color: $ripple-orange !important;
      i {
        color: $ripple-yellow !important;
      }
      &:hover,
      &:focus {
        background-color: lighten($ripple-orange, 35%);
        box-shadow: 0px 2px 1px $ripple-orange;
      }
    }
  }

  .node-page-full {
    header {
      display: none;
    }
  }

  .btn {
  //  border-radius: 0.25rem;
    font-family: $font-ripple-headings;
  }

  .chosen-container .chosen-results li:hover,
  .chosen-container .chosen-results li.highlighted {
    background-color: $ripple-orange;
  }

  #edit-submit.btn {
    //   @include clearfix;
    clear: both;
    display: block;
    background-color: $ripple-orange !important;
    border-color: $ripple-orange !important;
    &:hover,
    &:focus {
      background-color: darken($ripple-orange, 10%) !important;
    }
  }

  // .btn-new {
  //   @include btn-yellow;
  // }

  .view {
    .view-header {
      margin-bottom: 2rem;
      font-family: $font-ripple-text;
      color: $ripple-black; // $primary-color;
      font-size: $h5-font-size;
    }
    .views-table th,
    .views-table th a {
      color: $ripple-black;
    }
    .view-empty .create {
      max-width: 470px;
      padding: 2rem;
      margin-top: 3rem;
      border: 4px dashed $ripple-blue-green; // $coral;
      border-radius: 3rem;
      font-weight: bold;
      font-size: 1.2rem;
      p {
        font-weight: bold;
      }
    }

    .view-empty .contact-support {
      max-width: 550px;
      padding: 2rem;
      margin-top: 3rem;
      border: 4px solid $ripple-blue-green; //$aqua;
      border-radius: 3rem;
      font-weight: bold;
      font-size: 1.2rem;
      color: $coral;
      p {
        font-weight: bold;
      }
    }

    .view-empty,
    .view-header {
      a.btn {
        font-family: $font-ripple-headings !important;
      }
      a:not(.btn) {
        color: $yellow !important;
      }
    }

    a[href*="/edit"] {
      font-weight: 600;
      display: block;
      text-align: right;
      text-transform: uppercase;
      font-size: 0.9rem;
      color: $ripple-blue !important;
    }
    .views-field-delete-link {
      text-align: center;
    }
    a[href*="/delete"] {
      font-weight: 600;
      text-transform: capitalize;
      border-radius: 50%;
      background-color: $red;
      font-family: $font-ripple-headings;
      color: $white !important;
      font-size: 14px;
      display: inline-block;
      padding: 3px 8px;
      text-align: center;
      &:hover,
      &:focus {
        text-decoration: none;
        background-color: lighten($red, 10%);
      }
    }
  }

  #thank-you {
    position: fixed;
    top: 140px;
  }

  #thank-you-submit {
    // position: fixed;
    // bottom: 6rem;
  }

  #thank-you,
  #thank-you-submit h4 {
    z-index: 999;
    border-radius: 1.5rem;
    border: 2px solid $ripple-orange; // $berry;
    // background-size: $yellow;
    min-width: 540px;
  }

  .layout-content {
    > .block {
      padding-top: 2.5rem;
      padding-bottom: 3rem;
      //    min-height: 90vh;
      .block-title {
        margin-bottom: 2rem;
        color: $ripple-black; // $ripple-primary;
        font-size: 1.6em;
      }
      .block-inner {
        //margin: 0 auto;
      }
    }

    > .block:not(:last-child) {
      // &:after {
      //   content: " ";
      //   border-bottom: 1px solid $gray-500;
      //   left: -50%;
      //   right: -50%;
      //   width: 200vw;
      //   // left: 0;
      //   // right: 0;
      //   // width: 100vw;
      //   margin-top: 3rem;
      //   position: absolute;
      //   z-index: 9;
      // }
    }
  }

  a.show-bs-pack-select {
    color: $primary-color;
    font-size: 0.75rem;
    margin-left: 0.5rem;
  }

  .drupal-form {
    .form-submit {
      margin-top: 2rem;
      min-width: 6rem;
    }
    .description {
      // color: $gray-700;
      font-family: $font-ripple-text;
      font-size: 1rem;
    }
    label {
      font-weight: bold;
      margin-bottom: 0;
    }
    .form-item {
      margin-bottom: 1rem;
    }

    .well {
      border:2px solid $ripple-black;
      padding: 20px;
      border-radius: 40px;
      margin-bottom: 20px;
      background-color: white;
    }

    // .form-item-question1-new label,
    // .form-item-question2-new label {
    //   display: none;
    // }
    .form-checkboxes {
      margin-top: 0.5rem;
      .form-item {
        display: block;
        label {
          font-weight: normal;
        }
      }
    }
  }

  .view-footer .error {
    position: fixed;
    top: 160px;
    z-index: 9999;
    min-width: 500px;
  }

    .node-body_set-tile {
    .body-set-details {
      margin-top: 1rem;
      .field-items {
        display: flex;
        .field-item:not(:last-child) {
          &:after {
            content: ", ";
          }
        }
      }
    }
  }

}

html body.admin-menu.page-ripple-admin .layout-subheader {
  margin-top: 29px !important;
}

.page-node-945816,
.page-ripple-support {
  .page-wrapper .layout-content {
    padding: 4rem auto;
  }
}


// Views and blocks
.page-ripple-admin {


.view-ripple-users.view-display-id-page {
  .view-header {
    font-size: 1em;
  }
  caption {
    color: $ripple-blue-green;
    font-size: 1.25em;
    font-weight: bold;
    text-align: left;
    caption-side: top;
  }
  td.views-field-nothing {
    vertical-align: middle;
  }
  a.remove-staff-member {
    color: $red;
    user-select: none;
    display: inline-block;
    padding: 0.375em 0.5em;
    background-color: white;
    border-color: white; //$red;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:hover {
      border-color: $red;
      background-color: $red;
      color: $white;
    }
  }
}


.view-ripple-question-packs,
.view-ripple-set-packs {
  td.views-field-delete-node,
  td.views-field-nothing {
    font-size: 1.1rem;
    text-align: center;
    width: 40px;
  }
}

.view-question-pairs,
.view-ripple-question-packs,
.view-ripple-set-packs,
.view-body-sets-packs,
.view-ripple-schools {
  .saved {
    font-weight: bold;
    border-radius: 5px;
    position: absolute;
    font-size: 1rem;
    background: $ripple-green;
    color: white;
    border-radius: 1.5rem;
    padding: 8px 20px;
    margin-top: -40px;
    z-index: 999;
    white-space: nowrap;
  }

  .school-name {
    font-style: italic;
    font-weight: 300;
    color: $gray-600;
  }

  table {
    td,
    td select {
      font-size: 0.9rem;
    }
    td {
      position: relative;
      vertical-align: middle;
      padding: 1.5rem 0.75rem;
    }
    th {
      line-height: 1.2;
      white-space: nowrap;
      &:nth-child(1) {
        width: 200px;
      }
      &:nth-child(2) {
        width: 280px;
      }
    }
  }

  .views-exposed-widgets {
    margin-bottom: 1rem;
    margin-top: 1.5rem;
  }
  .views-exposed-widget {
    display: flex;
    text-align: left;
    align-items: center;
    margin-bottom: 1.5rem;
    label {
      display: none;
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
}

.view-tracking {
  .table {
    caption {
      color: $ripple-blue-green;
      font-weight: bold;
      text-align: left;
      caption-side: top;
    }
  }

  .views-exposed-widgets {
    margin-bottom: 1rem;
    margin-top: 1rem;
    // display: flex;
  }
  .views-reset-button {
    position: absolute;
    right: 0;
    /* top: 0; */
    margin-bottom: 0;
    bottom: 0;
  }
  #edit-reset {
    @extend .btn-sm;
  }
  #edit-created-wrapper input {
    width: 150px;
  }
  #edit-created-wrapper .views-widget .form-item,
  #edit-created-wrapper .views-widget {
    display: flex;
    align-items: center;
  }
  .form-item-created-min {
    margin-right: 10px;
  }
  .views-exposed-widget {
    display: flex;
    text-align: left;
    align-items: center;
    margin-bottom: 1rem;
    label {
      margin: 0 10px 0 0;
    }
    // label {
    //   display:none;
    //   margin-bottom: 0;
    //   margin-right: 1rem;
    // }
  }
}

.create-question-pack-form,
.create-body-sets-pack-form {
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .list-origin {
    // background-color: $gray-200;
    border: 2px solid $gray;
    color: $gray-600;
    li {
      color: $gray-600;
      position: relative;
    }
  }

  .list-drop {
    background-color: $white;
    border: 2px solid $ripple-blue;
  }

  .list-origin,
  .list-drop {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    padding: 0.5rem; // 0.5rem 1rem;
  }

  .arrow {
    left: 48%;
    margin-top: -1.8rem;
    img {
      width: 3rem;
      transform: rotate(35deg);
    }
  }

  .list-drop li:before {
    content: "✓";
    color: $ripple-blue;
    margin-right: 0.5rem;
    position: absolute;
    left: 0;
  }
  .list-drop li {
    padding-left: 25px;
    position: relative; // possible drag drop break? does this break stuff?
  }

  label {
    font-weight: bold;
  }

  #body-set-attribute-filters {
    columns: 3;
    .filter-cat {
      //flex: 0 0 20%;
      break-inside: avoid;
    }
    label {
      font-weight: normal;
      cursor: pointer;
      font-size: 0.9rem;
      line-height: 1.2;
      display: inline;
      &:hover {
        color: $primary-color;
      }
    }
  }

  .list-wrapper {
    flex: 0 0 calc(50% - 0.5rem);
    .body-sets-list-header {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      padding: 5px;
    }
  }

  .over {
    border-color: $yellow;
    background: $yellow-pastel;
  }
  .draggable {
    padding: 0.25rem;
    &:hover {
      cursor: pointer;
      background-color: mix($ripple-blue, $white, 10%); //$aqua-pastel;
    }
    // border: solid 2px gray;
  }
  .dropped {
    border-color: $primary-color;
  }
  .draggable.dropped {
    color: $primary-color;
    font-weight: 600;
  }

  .dropped2 {
    border-color: $coral;
  }

  .draggable.dropped2 {
    color: $coral;
  }

  .fbox {
    overflow: scroll-y;
    height: 400px;
    overflow-x: hidden;
    max-width: 100%;
    overflow-y: scroll;
    .body-set {
    }
  }
}

.view-question-pairs {
  th.views-field-field-question-pair-question1,
  th.views-field-field-question-pair-question2 {
    width: 30%;
  }
  td.views-field-title {
    //color: $gray;
  }
}


}
