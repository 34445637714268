// Homepage
.front {
  // Responsive heights
  $stream-image-height: 150px;
  $stream-image-height-md: 180px;
  $stream-image-height-lg: 240px;
  $stream-image-height-xl: 270px;

  background-color: $teal;

  .mask1 {
    @include media-breakpoint-up(md) {
      mask-image: url("../images/wobbly-rectangle.svg");
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: contain;
    }
  }


  .page-wrapper {

   background: linear-gradient(
    to bottom,
    white 0%,
    white 50%,
    $teal 50%,
    $teal 100%
  );

  min-height: 60vh;
    @include media-breakpoint-up(lg) {
      min-height: calc(100vh - 120px - 390px);
    }
  }

  .page-outer-wrapper {
    position: relative;
    &:before,
    &:after {
      content: " ";
      display: block;
      position: absolute;
      left: 0;
      bottom: 10vh;
      width: 20%;
      height: 90vh;
      background-image: url("../images/hero-shapes.png");
      background-size: 150%;
      background-repeat: repeat;
      //   transform: rotate(45deg);
      opacity: 0.15;
    }
    &:after {
      left: auto;
      right: 0;
      background-position: right center;
      // transform: rotate(325deg);
    }
  }

  .main-content {
    .node header {
      display: none;
    }

    background: linear-gradient(
      180deg,
      transparent,
      transparent ($stream-image-height/2),
      $teal ($stream-image-height/2),
      $teal
    );

    @include media-breakpoint-up(md) {
      background: linear-gradient(
        180deg,
        transparent,
        transparent ($stream-image-height-md/2),
        $teal ($stream-image-height-md/2),
        $teal
      );
    }
    @include media-breakpoint-up(lg) {
      background: linear-gradient(
        180deg,
        transparent,
        transparent ($stream-image-height-lg/2),
        $teal ($stream-image-height-lg/2),
        $teal
      );
    }


  }


  .big-exclamation-icons {
    flex: 0 0 35px;
    max-width: 35px;
    height: $stream-image-height;

    @include media-breakpoint-up(md) {
      height: $stream-image-height-md;
      flex: 0 0 40px;
      max-width: 40px;
    }
    @include media-breakpoint-up(lg) {
      height: $stream-image-height-lg;
    }
    @include media-breakpoint-up(xl) {
      height: $stream-image-height-lg;
      flex: 0 0 45px;
      max-width: 45px;
    }

    .flip {
      transform: rotate(180deg);
      transform-origin: center center;
    }
  }

  .stream-option {
    @include media-breakpoint-down(xs) {
      padding-left: 0;
      padding-right: 0;
    }

    a.img-wrapper {
      width: 100%;
      background-size: cover;
      height: 150px;
      transition: all 0.25s ease-in-out;
      @include media-breakpoint-up(sm) {
        border-radius: 25px;
      }
      @include media-breakpoint-up(md) {
        height: $stream-image-height-md;
      }
      @include media-breakpoint-up(lg) {
        height: $stream-image-height-lg;
      }
    }

    &:hover,
    &:focus {
      a.img-wrapper {
        scale: 1.1;
        box-shadow: 0 0 2px 2px white;
      }
    }

    .btn {
      text-transform: none;
      margin-top: -30px;
      font-size: $input-btn-font-size-lg;
      line-height: 1.4;
      padding: 0.375rem 1.5rem 0.475rem;
      border-radius: 2rem;
      border: 2px solid white;
      @include media-breakpoint-up(lg) {
        font-size: 1.5em;
        padding: 0.375rem 2rem 0.475rem;
      }
    }
  }
}

// Body Content
.page-wrapper {
  min-height: 60vh;
  .decorative-exclamation-icons {
    flex: 0 0 25px;
    max-width: 25px;
    z-index: 999;
    left: 0;
    right: 0;
    margin-top: -38px;

    @include media-breakpoint-up(md) {
      margin-top: -54px;
      flex: 0 0 30px;
      max-width: 30px;
    }

    // @include media-breakpoint-up(md) {
    // //  height: 100px;
    //   flex: 0 0 30px;
    //   max-width: 30px;
    // }
    // @include media-breakpoint-up(lg) {
    // //  height: 150px;
    //   flex: 0 0 30px;
    //   max-width: 30px;
    // }

    .flip {
      transform: rotate(180deg);
      transform-origin: center center;
    }
  }

  // All full view nodes
  .node-full {
    img {
      max-width: 100%;
      height: auto;
      border-radius: 1.5rem;
      margin-bottom: 1rem;
    }

    .field-name-field-banner {
      display: none;
    }
  }

  .body-content {
    h2,
    h3,
    h4,
    h5 {
      color: $navy; //-dark;
    }
    h2 + p,
    h3 + p,
    p + h3,
    p + h5,
    p + h4,
    p + h6,
    ul + h3,
    ul + h4,
    ul + h5,
    ul + h6 {
      margin-top: 1.5rem;
    }
    li a:not(.btn),
    p a:not(.btn) {
      @include link-underline;
    }
    ul {
      margin-bottom: 2.5rem;
      list-style: none;
      li {
        margin-bottom: 0.5rem;
        @include media-breakpoint-down(sm) {
          line-height: 1.3;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    ul:not(.list-unstyled) li {
      &:before {
        content: "•";
        color: $aqua;
        vertical-align: top;
        display: inline-block;
        width: 1rem;
        margin-left: -1rem;
        @include media-breakpoint-up(lg) {
          line-height: 1.5rem;
          font-size: 1.4rem;
        }
      }
      ul {
        margin: 0.25rem 0 0 1rem;
        li {
          margin-bottom: 0.45rem;
        }
        li:before {
          content: "-";
        }
      }
    }
  }

  // Basic pages
  .node-webform-full,
  .node-page-full {
    // .field-name-field-body,
    .field-name-body {
      margin: 0 auto 2.5rem;
      @include make-col(12);

      p:first-child,
      p.lead {
        text-align: center;
      }

      @include media-breakpoint-up(lg) {
        text-align: center;
      }
      @include media-breakpoint-up(xl) {
        @include make-col(10);
      }
    }
  }

  .webform-client-form {
    .form-item {
      margin-bottom: 1.5rem;
    }
    .form-item:not(.form-type-checkbox) > label:not(.error) {
      color: $text-color; // $primary-color;
      &.env-endeavour & {
        font-family: $font-museo;
      }
      font-size: 1.2rem;
      line-height: 1.3;
      margin-bottom: 0rem;
    }
    // if only you could negate multiple :nots in CSS
    .form-item.form-type-radio > label:not(.error) {
      color: $default-color !important;
      font-weight: 400 !important;
      font-family: $font-family-base !important;
      font-size: 1rem !important;
      margin-bottom: 0.5rem !important;
    }

    .form-item.webform-component-radios > label:not(.error) {
      margin-bottom: 0.5rem !important;
    }

    .form-item.form-type-checkbox,
    .form-item.form-type-radio {
      margin-bottom: 0;
    }
    .form-actions {
      margin-top: 2rem;
      text-align: center;
      width: 100%;
    }
  }

  .node-webform-full {
    .webform-client-form {
      max-width: 43rem;
      margin: 0 auto;
    }
  }

  #edit-submitted-legal-info-terms-and-conditions {
    @include terms-and-conditions;
  }

  fieldset.webform-component--legal-info {
    .form-item {
      flex: 0 0 100%;
      margin-bottom: 0.5rem;
    }
    .description {
      margin-left: 0;
    }
  }
}

// About
.page-about {
  .node-page-full header {
    display: none;
  }

  .section-hero {
  }

  .block-team .view-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .img-wrapper {
      margin-bottom: 20px;
      margin-top: 10px;
      a:hover img {
        scale: 1.1;
        box-shadow: 0 0 2px 2px white;
      }
      img {
        //  border:4px solid white;// 5px solid #7b1950;
        border-radius: 20px; // 50%;
        border-radius: 50%;
        max-width: 150px;
        transition: all 0.25s ease-in-out;
      }
    }

    .views-row {
      margin-bottom: 30px;
    }

    .position {
      line-height: 1;
    }

    .views-row:last-child() {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: center;
      .views-row {
        max-width: 33%;
        margin-bottom: 0;
        text-align: center;
        flex: 0 0 33%;
      }
    }
  }
}

// Legal/Terms/Privacy Pages
.page-privacy,
.page-terms {
  .node-page-full .field-name-body {
    @include media-breakpoint-up(sm) {
      text-align: left;
      font-size: 1rem;
      font-weight: inherit;
      font-family: inherit; //$font-default;
      p {
        line-height: inherit;
      }
    }
  }
}

//
// Signup & pricing
// --------------------------------------------------

$signup: 470221;
$signup_form: 322346;

.page-node-#{$signup_form}:not(.page-done),
.page-node-#{$signup} {
  .layout-content,
  .layout-below-content {
    font-family: $font-din;
  }

  .layout-content {
    background-color: $primary-color;
    background-image: radial-gradient(
      500px circle at 50% 90%,
      mix($teal, $aqua, 30%),
      $teal
    );
    // background-color: $aqua;
    color: white;
    position: relative;
    padding-bottom: 2rem !important;

    &:after,
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 0;
      content: "";
      background-image: url("#{$img-path}/for-students-shapes.png");
      opacity: 0.3;
      background-repeat: no-repeat;
      background-position: left top;
    }

    &:before {
      background-position: right bottom;
    }

    header .node-header:after {
      border-top-color: $aqua-pastel;
      margin-top: 1.5rem;
    }
  }

  // Signup pricing
  .node-full .field-name-body {
    font-size: 1.2em;
    //  font-family: $font-family-base;
    margin-bottom: 0 !important;
    p:first-child {
      font-family: $font-din-bold !important;
      font-size: 1.1em;
      //font-size: 1.1rem;
    }
    // p strong {
    //   color: $aqua-pastel;
    // }
    ul {
      //font-family: $font-family-base;
      max-width: 33rem;
      margin-left: auto;
      margin-right: auto;
      @include tick-list(white);
    }
  }
}

#block-cl-signup-pricing-block {
  margin-bottom: 5rem;
  position: relative;

  .btn.font-cursive.btn-smm {
    font-size: 1rem;
  }

  .features-heading {
    position: relative;
    @include media-breakpoint-up(lg) {
      &:before {
        content: "";
        position: absolute;
        border-left: 5px dotted #73cac6;
        left: calc(50% - 3px);
        top: 31px;
        height: 19px;
        width: 100%;
      }
      &:after {
        content: "";
        position: absolute;
        border-top: 5px dotted #73cac6;
        left: 0;
        right: 0;
        height: 5px;
        top: 58px;
        width: 90%;
        left: 5%;
      }
    }
  }

  h2 {
    font-size: 1.75rem;
    @include media-breakpoint-up(lg) {
      font-size: 2rem;
    }
  }

  .elements-item {
    @include media-breakpoint-up(lg) {
      &:before {
        content: "";
        position: absolute;
        border-left: 5px dotted #73cac6;
        left: calc(50% - 3px);
        top: -4rem;
        height: 50px;
      }
    }
  }

  .elements-item .card {
    h3 {
      text-transform: uppercase;
    }
    h3 span {
      font-size: 1rem;
      text-transform: none !important;
      display: block;
    }
  }

  .card {
    border: none;
  }
  .card .card-header {
    border-bottom: 1px solid $white;
    cursor: pointer;
  }

  form {
    .enter-number-students {
      //font-family: $font-cursive;
      @include media-breakpoint-down(sm) {
        text-align: center;
      }
      font-size: 1.1rem;
      line-height: 1.2;
      input {
        width: 9rem;
        font-size: 1.4rem;
        background-color: transparent;
        color: $white;
        border-color: $white;
        text-align: center;
        &::-webkit-input-placeholder {
          color: white;
        }
        &:-ms-input-placeholder {
          color: white;
        }
        &::placeholder {
          color: white;
        }
      }
    }
  }
}

.page-node-#{$signup_form} {
  .layout-form {
    background-color: lighten($aqua-pastel, 10%);
  }
  .signupform-nav-wrapper {
    @include media-breakpoint-down(sm) {
      position: relative;
      top: auto;
    }
  }

  #signupform-nav {
    li {
      text-align: center;

      div.nolink {
        padding: 0.75rem 1.5rem 1.25rem 0;
        font-weight: 700;
        //padding: .75rem 0 0 0;
      }

      a {
        margin: 0 0.25rem;
        border-radius: 1.5rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        padding: 0.75rem 1rem 1.25rem;
        &.active {
          background-color: $white !important;
          color: $navy !important;
        }

        @include media-breakpoint-up(lg) {
          padding: 0.75rem 1.5rem 1.25rem;
        }
      }
    }
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      border-radius: 0.25rem;
      overflow: hidden;
      li a {
        border-radius: 0;
        margin: 0;
      }
    }
  }
}

#webform-client-form-#{$signup_form} {
  // Validate
  label.error {
    font-size: $font-size-xs;
    color: $coral-dark;
    padding: 0 0.5rem;
  }

  .fieldset-description {
    margin: 0 0 1.5rem;
    font-size: $font-size-sm;
  }

  fieldset {
    margin-bottom: 1.5rem;
    position: relative;
    border-radius: 1.5rem;
    padding: 7rem 2rem 3rem 2rem;
    background-color: white;
    box-shadow: 0 0 0.2rem $aqua;

    .form-item:not(.form-type-checkbox) > label:not(.error) {
      font-weight: 600;
      font-size: 1.2rem;
    }

    .form-checkboxes {
      margin-top: 0.25rem;
    }
    .form-type-checkbox {
      margin-bottom: 0rem;
      display: block !important;
    }

    input,
    select,
    textarea,
    .form-control {
      color: $navy-dark;
    }

    legend {
      color: $primary-color;
      margin-bottom: 1.5rem;
      position: absolute;
      left: 0;
      line-height: 1.1;
      top: 1.5rem;
      text-align: center;
      font-family: $font-headings;
      @include header-dots($aqua, 6px);
      &:after {
        width: 24px;
      }
    }

    fieldset {
      padding: 0;
      box-shadow: none;
      border-radius: 0;
      legend {
        // text-align: left;
        font-family: $font-family-base;
        text-transform: uppercase;
        color: $aqua;
        top: auto;
        left: auto;
        position: relative;
        font-size: 1.1rem;
        &:after {
          display: none !important;
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid $gray;
        padding-bottom: 1rem;
        margin-bottom: 2rem;
      }
    }
  }

  @include media-breakpoint-up(md) {
    fieldset {
      padding-top: 6rem;
      fieldset {
        padding-top: 0;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .fieldset-description {
      text-align: center;
      margin: 0 1rem 1.5rem;
    }

    .fieldset-wrapper {
      display: flex;
      flex-wrap: wrap;
      clear: both;
      position: relative;
      justify-content: space-between;
      margin-left: -0.75rem;
      margin-right: -0.75rem;
    }
    .fieldset-wrapper > .form-item {
      padding: 0 0.75rem;
    }

    .fieldset-wrapper > .webform-component-email,
    .fieldset-wrapper > div[class*="-state"],
    .fieldset-wrapper > div[class*="-postcode"],
    .fieldset-wrapper > div[class*="-school-po"],
    .fieldset-wrapper > div[class*="-suburb"],
    .fieldset-wrapper > div[class*="-country"],
    .fieldset-wrapper > div[class*="-phone"] {
      flex: 0 0 50%;
    }

    fieldset.webform-component--school-info.fieldset-wrapper
      > .form-item:nth-child(3) {
      flex: 0 0 50%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
  }

  // Primary contact
  fieldset.webform-component--pc-info {
  }

  // Super teacher / School Program Administrator,  Principal Info
  #edit-submitted-contact-info-spa-info-same-as-above,
  #edit-submitted-contact-info-principal-info-same-as-above {
    .form-item {
      text-align: center;
      margin-bottom: 0;
    }
    label {
      font-family: $font-family-base;
      color: $yellow;
      font-size: 0.9rem;
    }
  }

  // comments
  fieldset.webform-component--legal-info {
    .form-item {
      flex: 0 0 100%;
    }
  }

  .form-actions {
    text-align: center;
  }
}


// Free Trial (free-trial)
// ------------------
.page-node-861431 {

  background-color: $navy-dark;

  .layout-hero {
   // @include hero-background($berry, lighten($berry, 10%));

    $bg-color: $navy-dark;
    $burst:$navy;
    background-color: $bg-color;
    background-image: radial-gradient(400px circle at 50% 70%, $burst, $bg-color);
    @include media-breakpoint-up(lg) {
      background-image: radial-gradient(500px circle at 70% 50%, $burst, $bg-color);
    }


    @include media-breakpoint-down(sm) {
      background-image: none !important;
    }

    @include media-breakpoint-up(sm) {
      background-size: 440px;
      background-position: 130% 10%;
      background-repeat: no-repeat;
    }

    @include media-breakpoint-up(lg) {
      background-size: 560px;
      background-position: 130% 50%;
      background-repeat: no-repeat;
    }

    @include media-breakpoint-up(xl) {
      background-size: 660px;
      background-position: 120% 0%;
    }

    @include media-breakpoint-up(xxl) {
      background-size: 700px;
      background-position: 80% 0%;
    }


    .shapes {
      position: absolute;
      opacity: 0.1;
      z-index: 0;
    }

    ul {
      font-size: 1.15rem;
    }

    ul span.font-cursive {
      font-size: 1.3rem;
    }
  }

  .block-webform {

    .webform-client-form>div {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      padding: 0 $gap;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    @include media-breakpoint-up(lg) {
      .form-item {
        flex: 0 0 50%;
        padding: 0 $gap;

        //display: inline-block;
        //width: 47%;
        &.webform-component-date,
        &.webform-component-textarea {
          //  width: 100%;
          flex: 0 0 100%;
          // padding: 0;
        }

        .form-item {
          flex: auto;
        }

        .webform-datepicker {
          margin-top: 0;
        }

        &.webform-component-date .webform-datepicker {
          justify-content: left;
          flex: 0 0 70%;

          .form-item {
            padding: 0 $gap 0 0;
          }
        }
      }

      .webform-component--legal-info--terms-and-conditions .description {
        margin-left: 0;
      }

      fieldset {
        flex: 0 0 100%;
        padding: 0;
      }

      fieldset .form-item .form-item {
        padding: 0;
      }
    }
  }

  .tick-list-white {
    font-size: 1.1rem;
  }

  .plans-from {
    font-size: 1.1em;

    p {
      margin: 0;
      line-height: 1.2;
    }

    .price {
      font-size: 3rem;
    }
  }

  // Section - partner logos
  .layout-partner-schools {
    .school-logos {
      justify-content: center;
      align-items: center;

      li {
        max-width: 100px !important;

        @include media-breakpoint-up(md) {
          max-width: 110px !important;
        }
      }
    }

  }

  // Quotes / testimonials
  .block-quotes {
    .view-quotes .author {
      text-align: center;
      display: block !important;
    }
    .quote {
      font-family: $font-cursive;
      max-width: 800px;
      margin: 0 auto 1.5rem;
      text-align: center;
    }
    .has-profession .author {
      //margin-right: 1rem;
      &:after {
        content: ", ";
        //width:2px;
        // position: absolute;
        // height: 1rem;
        // margin-top: 0.25rem;
        // background-color: $berry;
        // content: " ";
        // margin-left: 0.55rem;
      }
    }
    .author, .profession {
      color: $berry;
      font-weight: 600;
      display: inline-block;
      font-size: 1.1rem;
    }



    &.author-white {
      .author, .profession { color: $white;}
      //.author:after { background-color: $white;}
    }

    &.author-aqua {
      .author, .profession { color: $aqua-light;}
      //.author:after { background-color: $aqua;}
    }

    &.author-yellow {
      .author, .profession { color: $yellow-medium;}
      //.author:after { background-color: $yellow;}
    }
    &.author-default {
      .author, .profession { color: $default-color;}
      //.author:after { background-color: $yellow;}
    }

    @include media-breakpoint-down(sm) {
      padding: 0 1rem;
      .quote {
        font-size: 1.2rem;
      }
    }

  }


  .layout-testimonials {

    .slick-prev:before,
    .slick-next:before {
      color: $berry;
    }
  }

.layout-movement-mentoring-monitoring {
  .mmm-idea {
    border-radius: 50%;
    padding: 1rem 2.2rem;
    z-index: 8;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba($white, 0.1);
    transition: all 0.3s;
  //  background-color: $berry;

    @include media-breakpoint-between(sm, lg) {
      border-radius: 10%;
    }

    @include hover-ripple($berry-pastel);
    //border-color:white;

    .icon {
      width: 6rem;
      padding: 0 1em;
      background-color: transparent;//; // rgba($white, 0.1);

      path {
        fill: $yellow-medium;
      }

      @include media-breakpoint-between(sm, lg) {
        width: 4rem;
      }

    }

    p {
      font-family: $font-headings;
    }

    h3,
    a {
      color: $yellow-medium;
      text-decoration: none !important;
    }

    &:hover {
      background-color: $white;
      color: $coral;
      transform: scale(1.02);

      h3,
      a,
      svg path {
        color: $yellow;
        fill: $yellow;
      }
    }
  }

  .mmm-ideas>div {
    padding: 0;
    border-radius: 50%;
  }

  .mmm-ideas>div:nth-child(2) {

    &:before,
    &:after {
      content: "+";
      position: absolute;
      color: $coral;
      font-family: $font-headings;
      font-size: 7rem;
      z-index: 9;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: -4.5rem;
      line-height: 1;
    }

    @include media-breakpoint-up(md) {

      &:before,
      &:after {
        width: auto;
        height: 100%;
        top: 0;
        margin-top: 0;
      }

      &:before {
        left: -2.15rem;
      }

      &:after {
        right: -2.15rem;
      }
    }
  }
}


}


// General View Styling
.view-filters {
  .bef-select-as-checkboxes {
    .form-item {
      display: inline-block;
    }
  }
}

.views-exposed-form {
  position: relative;
  .views-exposed-widget {
    margin-bottom: 2.5rem;
    text-align: center;
    float: none;
    padding: 0;
  }
}

// Blocks
.block-logintoboggan,
#block-system-user-menu {
  font-size: 0.75rem;
}
