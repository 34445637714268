//
// Layout Styles
// --------------------------------------------------

.sticky-top {
  top: $header-height;
  @include media-breakpoint-up(lg) {
    top: $header-height-desktop;
  }
}

body {
  padding-top: $header-height;
  &.with-banner {
    padding-top: $header-height + $header-banner-height;
    .sticky-top {
      top: $header-height + $header-banner-height;
      @include media-breakpoint-up(lg) {
        top: $header-height-desktop + $header-banner-height;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: $header-height-desktop;
    &.with-banner {
      padding-top: $header-height-desktop + $header-banner-height;
    }
  }

  .layout-main {
    min-height: 400px;
    @include media-breakpoint-up(lg) {
      min-height: 500px;
    }
  }
}

// Container widths
#header > .container,
#footer > .container {
  @include media-breakpoint-down(lg) {
    max-width: none;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @include media-breakpoint-down(md) {
    max-width: none;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  @include media-breakpoint-down(xs) {
    max-width: none;
    padding-left: 1rem; //$grid-gutter-width/2;
    padding-right: 1rem; //$grid-gutter-width/2;
  }
}

.page-wrapper {
  .layout {
    position: relative;
    p:last-child {
      margin-bottom: 0;
    }
    p,
    li {
      font-weight: 500;
    }
    .btn {
      &:hover,
      &:focus,
      &:active,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        box-shadow: 0 0 0 0.15rem $white;
      }
    }
  }
  img {
    max-width: 100%;
    height: auto;
  }
}

// Header
#header {
  #navbar {
    height: $header-height;
  }

  .logo {
    width: 140px;
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
    padding: 0.75rem 0.25rem;
    margin-left: -0.25rem;

    img {
      // max-width: 164px;
    }

    @include media-breakpoint-up(lg) {
      // margin-top: 0.4rem;
      // margin-bottom: 0.4rem;
      width: 200px;
    }
  }

  .navbar-nav .nav-item:not(:last-child) {
    margin-right: 0.5rem;
  }

  .navbar-nav .nav-item .nav-link {
    color: $white;
    text-transform: uppercase;
    position: relative;
    font-weight: 400;
    letter-spacing: 0.025rem;
    &.active {
      color: $aqua-light;
    }
  }

  @include media-breakpoint-up(md) {
    .navbar-nav .nav-item:not(.link-login) .nav-link {
      // font-size: $font-size-small;

      &:after {
        opacity: 0;
        position: absolute;
        content: "";
        height: 0px;
        bottom: 10px; //-5px;
        width: 60px;
        left: calc(50% - 30px);
        background: url("#{$img-path}/icons/dash-dot-dash-primary-light.svg")
          no-repeat;
        background-size: cover;
        background-position: center center;
        transition: all 0.3s;
      }

      &:hover:after,
      &:focus:after {
        opacity: 1;
        bottom: 2px;
        height: 7px;
      }

      &.active {
        cursor: default;
      }
      &.active:after {
        background: none;
      }

      // &.active:after {
      //   background-color: transparent;
      // }
    }

    .navbar-nav .nav-item.link-login .nav-link {
      font-weight: 700;
      font-family: $font-din-bold;
      &:after {
        position: absolute;
        content: "";
        height: 7px;
        bottom: 2px;
        left: calc(50% - 30px);
        opacity: 1;
        width: 60px; //100%;
        background: url("#{$img-path}/icons/dash-dot-dash-highlight.svg") no-repeat;
        background-size: cover;
        background-position: center center;
      }
      &:hover,
      &:focus {
        color: $yellow-medium;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    #navbar {
      height: $header-height-desktop;
    }

    .navbar-nav .nav-item {
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }

    #navbar {
      justify-content: space-between;
    }

    // &.theme-transparent {
    //   background-color: transparent;

    //   .logo {
    //     margin-top: 1.5rem;
    //     margin-bottom: 1rem;
    //     padding: 0.6rem 0.25rem;

    //     img {
    //       max-width: 100%;
    //     }
    //   }

    //   #navbarLayout {
    //     //margin-top: 1rem;
    //   }
    // }
  }


  @include media-breakpoint-down(sm) {

    .navbar-nav .nav-item {
      margin-right: 0 !important;
    }

  }
}

// Above footer
.layout-above-footer {
  padding: 50px 0;
  background-color: $aqua-pastel;
  .block {
    position: relative;
  }
  &:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("../images/hero-shapes.png");
   background-size: 400px;//60%;
    background-repeat: repeat;
    background-position: center;
  //  transform: rotate(2deg);
    opacity: 0.15;
  }
}

// Footer
#footer {
  position: relative;

  .container > .row > div {
  }

  a:hover {
    color: mix($teal, $white, 25%);
  }

  .byline {
    font-size: 1.5rem;
    white-space: nowrap;
  }

  .nav-footer .nav-link {
    padding: 0.25rem 1rem;
  }

  .nav-social {
    img,
    svg {
      width: 2rem;
    }
  }

  .logo {
    max-width: 130px;

    @include media-breakpoint-up(sm) {
      max-width: 150px;
    }

    @include media-breakpoint-up(md) {
      max-width: 200px;
    }
  }

    .logo.ppf {
    @include media-breakpoint-up(md) {
      max-width: 170px;
    }
  }

  .subfooter {
    //  border-top: 1px solid $white;

    span {
      margin-bottom: 0.5rem;
    }

    @include media-breakpoint-up(md) {
      span:not(:last-child):after {
        width: 1px;
        position: absolute;
        height: 1rem;
        margin-top: 1px;
        background-color: rgba($white, 0.8);
        content: " ";
        margin-left: 1rem;
      }
    }
  }
}


//  Node header
.node-header {
  text-align: center;
}


// Feature block
// Feature Block
.feature-block {
  position: relative;
  color: $white;
  z-index: 2;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  &.no-text {
    height: 50vh;
    min-height: 400px;

  }
  &.with-image:before {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    content: "";
    background-color: rgba($navy, 0.7);
  }
}

.layout-messages {
  background-color: $primary-color;
  border-top: 1px solid $white;
}
