//
// Base Styles
// --------------------------------------------------

// Write some SASS!

.small, small {
  font-size: 80%;
}

b, strong {
  font-weight: 700;
}

.text-transform-none {
  text-transform: none !important;
}

.vertical-align-bottom {
  vertical-align: bottom;
}


// Colors - build background/text/link colours from your theme-colors() variable
@if variable-exists(theme-colors) {
  @each $color, $value in $theme-colors {
    .bg-#{$color} {
      background-color: $value !important;
    }

    @include media-breakpoint-up(lg) {
      .bg-extend-left-#{$color},
      .bg-extend-right-#{$color} {
        position: relative;
      }
      .bg-extend-left-#{$color}:after, .bg-extend-right-#{$color}::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 50vw;
        left: -50vw;
        top:0;
        z-index: -1;
        background-color: $value !important;
      }
      .bg-extend-right-#{$color}:after {
        left: auto;
      }
    }

    .text-#{$color} {
      color: $value !important;
    }
    a.link-#{$color}, .link-#{$color} a:not(.btn){
      color: $value !important;
    }
  }
}


a.link-underline, .link-underline a:not(.btn){
  @include link-underline;
}

a.remove-link-border,
a.remove-link-underline {
  border-bottom: none !important;
  color: inherit !important;
  &:hover, &:focus {
    color: inherit !important;
  }
}

a {
  transition: all 0.2s ease-out;
  color: $default-color;
  &:hover, &:focus {
    color: darken($default-color, 10%);
  }
}


a.text-primary:hover, a.text-primary:focus {
  color: $default-color !important;
}

.font-size-large.font-cursive {
  line-height: 1.3;
}

.font-din {
  font-family: $font-din;
}
.font-din-bold {
  font-family: $font-din-bold;
}

// .header-dots {
//   @include header-dots;
// }

.h1, h1 {
  font-weight: 400;
  line-height: 1.1;
  font-family: $font-headings;
  letter-spacing: 0.025rem;
  font-size: $h1-font-size;
}

.h2, h2 {
  font-weight: 400;
  line-height: 1.1;
  font-size: $h2-font-size;
  font-family: $font-headings;
  letter-spacing: 0.025rem;
}

.h3, h3 {
  font-weight: 400;
  font-size: $h3-font-size;
  font-family: $font-headings;
  letter-spacing: 0.02rem;
}

.h4, h4 {
  font-weight: 400;
  font-size: $h4-font-size;
  font-family: $font-din-bold;
}

.h5, h5 {
  font-weight: 400;
  font-size: $h5-font-size;
  font-family: $font-din-bold;
}

// Teal
a.btn {
  color: $white;
}
.btn, .btn-primary {
  color: $white;
  border-radius: $input-border-radius;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.7rem 1.2rem;
  font-size: 1rem;//$font-size-sm;
  border-color: $primary-color;
  background-color: $primary-color;
  &:hover, &:focus, &:active, &:not(:disabled):not(.disabled):active,&:not(:disabled):not(.disabled).active {
    border-color:darken($primary-color, 5%);
    background-color: darken($primary-color, 5%);
  }
  &:active, &:focus {
    box-shadow: 0 0 0 0.15rem $white;
    //box-shadow: 0 0 0 0.2rem rgba($primary-color, 0.2) !important;
  }
  &:hover, &:focus, &:active {
    color: $white;// !important;
  }
}

.btn, a[role="button"] {
  .svg, img, svg {
    height: 1rem;
    width: auto;
  }
}

a[role="button"] {
  .svg, img, svg {
    //position: absolute;
    margin-left: 0.25rem;
  }
}

// Buttons
@if variable-exists(button-colors) {
  @each $color, $value in $button-colors {
    .btn-#{$color} {
      background-color: $value;
      border-color: $value;
    }
  }
}

// Overrides
@mixin btn-yellow {
  &:hover, &:focus, &:active, &:not(:disabled):not(.disabled):active,&:not(:disabled):not(.disabled).active {
    border-color: mix($yellow, $coral, 85%);
    background-color: mix($yellow, $coral, 85%);
  }
}

.btn-yellow {
  @include btn-yellow;
}

.btn-coral {
  &:hover, &:focus, &:active, &:not(:disabled):not(.disabled):active,&:not(:disabled):not(.disabled).active {
    border-color: mix($coral, $berry, 90%);
    background-color: mix($coral, $berry, 90%);
  }
}

.btn-berry {
  &:hover, &:focus, &:active, &:not(:disabled):not(.disabled):active,&:not(:disabled):not(.disabled).active {
    border-color: $berry-medium;
    background-color: $berry-medium;
  }
}

.btn-berry-medium {
  &:hover, &:focus, &:active, &:not(:disabled):not(.disabled):active,&:not(:disabled):not(.disabled).active {
    border-color: darken($berry-medium, 5%);
    background-color:  darken($berry-medium, 5%);
  }
}

.btn-navy {
  &:hover, &:focus, &:active, &:not(:disabled):not(.disabled):active,&:not(:disabled):not(.disabled).active {
    border-color: $navy-dark;
    background-color: $navy-dark;
  }
}



.btn-info {
  color: $gray-800;
  &:hover, &:focus, &:active, &:not(:disabled):not(.disabled):active,&:not(:disabled):not(.disabled).active {
    border-color: darken($gray, 5%);
    background-color:  darken($gray, 5%);
    color: $gray-800;
  }

  &.disabled, &:disabled {
    color: $gray;
    background-color: lighten($gray, 10%);
    border-color:  lighten($gray, 10%);
  }

}

// Large buttons
.btn-lg {
  font-size: 1.1rem;
  @include media-breakpoint-up(md) {
    padding: 0.375rem 1.5rem 0.475rem;
    border-radius: 2rem;
    font-size: 1.3rem;
    img, svg {
      width: 0.7rem;
    }
  }
}

// Small/thinner buttons
.btn-sm {
  //font-size: .75rem;
  padding: 0.375em 1rem;
  line-height: 1.4;
}

// CTA buttons (on articles)
.btn-cta {
  padding: 1rem 1.5rem;
  font-size: 1.05rem;
  font-weight: 500;
  border-radius: 2rem;
}

// Next/Prev buttons
.btn.page-next {
  padding-left: 0.85rem;
  padding-right: 0.75rem;
  text-align: center !important;
}

.btn.page-previous {
  padding-left: 0.75rem;
  padding-right: 0.85rem;
  text-align: center !important;
}

// Tab buttons
.btn-tab {
  line-height: 1.2;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  text-align: center;
  transition: all 0.3s;
}

.btn-tab-left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 8.1rem;
  margin-right: -0.5rem;
  padding-right: 1.4rem;
  &:hover  {
    margin-right: 0;
  }

}

.btn-tab-bottom {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  width: 6rem;
  font-size: 0.75rem;
  margin-top: -0.5rem;
  padding-top: 1.1rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  &:hover  {
    margin-top: 0;
  }
}



// Readmore btn
.readmore:not(.collapsed) {
  svg {
    transform: rotate(180deg);
  }
}

.font-headings {
  font-family: $font-headings;
  font-weight: 400;
  letter-spacing: 0.02rem;
}

.font-cursive {
  font-family: $font-cursive;
}

.font-size-large {
  font-size: $font-size-lg;
}

.font-size-xl {
  font-size: $font-size-xl;
}
.font-size-xxl {
  font-size: $font-size-xxl;
}


.font-size-xs {
  font-size: $font-size-xs;
}
.font-size-sm, .font-size-small {
  font-size: $font-size-sm;
}
.font-weight-semibold {
  font-weight: $font-weight-semibold !important;
}

.no-underline {
  text-decoration: none !important;
}
