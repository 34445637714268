$gap: $grid-gutter-width / 2; // (15px typically)

//
// Base Styles
// --------------------------------------------------


html {
  margin:0;
}

body {
  overflow-x:hidden;
  margin:0;
  font-family: $font-family-base;
  color: $text-color;
  font-weight: 500;
}

.hover {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

// debugging
.todo {
  border:4px dashed red;
  background-color: white;
  padding: 20px;
  color: darken(red, 2%) !important;
  font-weight: 600 !important;
  text-align: center  !important;
  max-width: 45rem;
  margin: 20px auto;
}


//
// Typography
// --------------------------------------------------

.fa {
  vertical-align: middle;
}

a[href^="tel"]{ color:inherit; text-decoration: none !important; }


//
// MISC
// --------------------------------------------------
.webform-progressbar {
  display: none !important;
}

svg, img.svg-icon {
  width: 1rem;
}

a.bg-secondary:focus, a.bg-secondary:hover {
  background-color: $secondary-color !important;
}

//preloader
.loader {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  background: $aqua;
  img {
    width: 2rem;
  }
}

.error {
  color: $red;
  font-weight: 600;
  p, a {
    color: $red !important;
    font-weight: 600 !important;
  }
}

.link-red, .text-red, .link-red a, .text-red a {
  color: $red !important;
}

.rounded-large {
  border-radius: 1.5rem;
}



//
// Admin styles
// --------------------------------------------------

#user-login-form {
  max-width: 40rem;
}

body.cke_editable {
  padding: 20px !important;
}


html body.admin-menu {
  #header {
    margin-top: 29px !important;
  }
}



//
// Maintenance
// --------------------------------------------------
body.maintenance-page {
  .maintenance-wrapper {
    height: 100vh;
    .navbar-brand img {
      width: 200px !important;
    }
  }
}

//
// Tabs / "view/edit"a etc
// --------------------------------------------------

$tabs-layout-bg: #333;
$tab-bg: #333;
$tab-color: white;
$tab-color-hover: $coral;
$tab-color-active: #333;
$tab-bg-active: $yellow;

.layout-tabs {
  overflow: hidden;
  bottom:0;
  width: 100%;
  background: $tabs-layout-bg;
  clear: both;
  margin: 0;

  .container-fluid {
    flex-wrap: wrap;
  }

  ul {
    border: none !important;
    list-style: none !important;
    margin-bottom: 0;
    > li {
      display: inline-block;
    }
  }

  ul > li > a {
    background-color: $tab-bg;
    border-radius: 20px;
    display: block;
    border:none;
    border: solid 1px $tab-color;
    color: $tab-color;
    margin: 0 10px 0 0;
    padding: 9px 20px;
    font-size: 0.9em;
    &:visited {
      color: $tab-color;
    }
    &:hover {
      color: #fff !important;
      background-color: $tab-color-hover;
    }
    @include media-breakpoint-down(md) {
      margin-bottom: 10px;
    }
  }
  ul > li.active a {
    border:none !important;
    color: $tab-color-active !important;
    background-color: $tab-bg-active !important;
    cursor: default;
  }
  span {
    font-size: 1.5em;
    color: rgba($white, 0.50);
  }

}


#admin-menu {
  z-index: 9999;
}

// Node unpublished
.node-unpublished {}

