// Endeavour
// Violet 1 100 89 167 6459a7
// Purple 1 152 61 144 983d90
// Red 1 211 40 70 d32846
// Dark Orange 1 241 86 65 f15641
// Yellow 1 255 194 14 ffc20e
// Green 93 169 93 3e8549
// Navy 1 33 61 118 213d76
$endeavour-violet: #6459a7;
$endeavour-violet-dark: #504b8a;
$endeavour-purple: #983d90;
$endeavour-orange: #f15641;
$endeavour-red: #d32846;
$endeavour-yellow: #ffc20e;
$endeavour-green: #3e8549;
$endeavour-blue: #213d76;

// Black 0 0 0 000000
// 85% Black 77 77 79 4d4d4f
// 60 % Black 128 130 133 808285
// 30% Black 188 190 192 bcbec0
// 10% Black 230 232 232 e6e7e8

$endeavour-dark-gray: #4d4d4f;
$endeavour-light-gray: #bcbec0;
// Black 0 0 0 000000
// 85% Black 77 77 79 4d4d4f
// 60 % Black 128 130 133 808285
// 30% Black 188 190 192 bcbec0
// 10% Black 230 232 232 e6e7e8
$endeavour-theme-colors: ();
$endeavour-theme-colors: map-merge((
  'endeavour-default': $endeavour-dark-gray,
  'endeavour-purple': $endeavour-purple,
  'endeavour-violet': $endeavour-violet,
  'endeavour-blue': $endeavour-blue,
  'endeavour-green': $endeavour-green,
), $endeavour-theme-colors);


body.page-endeavour {

  @if variable-exists(endeavour-theme-colors) {
    @each $color, $value in $endeavour-theme-colors {
      .bg-#{$color} {
        background-color: $value !important;
      }
      .text-#{$color} {
        color: $value !important;
      }
    }
  }

  &.marketing-theme .webform-client-form {
    .form-item:not(.form-type-checkbox)>label:not(.error) {
      color: $endeavour-blue;
      font-family: $font-museo !important;
    }
    .form-item.form-type-radio>label:not(.error) {
      color: $endeavour-dark-gray !important;
      font-family: $font-museo !important;
    }
    label.error {
      color: $endeavour-red !important;
    }

  }

  #footer {
    background-color: $endeavour-violet-dark !important;
  }
}

body.page-endeavour .page-wrapper {

  color: $endeavour-dark-gray;
  font-family: $font-museo;

  a:not(.btn) {
    color: $endeavour-violet;
  }

  .h1, .h2, h1, h2 {
    font-family: $font-museo;
    font-weight: 900;
  }

  .h3, .h4, h3, h4{
    font-family: $font-museo;
  }

  .form-required {
    color: $endeavour-red;
  }

  .alert-danger {
    color: $endeavour-dark-gray;
    font-size: 1.1em;
    ul {
      margin-bottom: 0;
    }
    a:not(.close) {
      color: $endeavour-dark-gray !important;
      text-decoration: underline;
    }
  }

  .alert-info {
    background-color: transparent;
    border-color: $endeavour-purple;
    border-width: 2px;
    color: $endeavour-dark-gray;
    font-weight: bold;
  }

  .link-underline a:not(.btn), a.link-underline {
    color: $endeavour-purple;
    border-bottom-color: $endeavour-violet;
    &:focus, &:hover {
      border-bottom-color: $endeavour-purple;
    }
  }


  .form-item .description {
    color: $endeavour-dark-gray;
  }

  .form-control:focus {
    border-color: $endeavour-violet;
  }

  .form-check label span:after {
    background-color: $endeavour-purple;
  }

  .btn {
    font-weight: 700;
    font-size: 1.1em;
    background-color: $endeavour-purple !important;
    border-color: $endeavour-purple !important;
    &:hover, &:focus {
      background-color: $endeavour-dark-gray !important;
      border-color: $endeavour-dark-gray !important;
    }
  }

  // Signup
  #node-1137506 {
    header {
      display:none;
    }
    &.node-page-full .field-name-body {
      max-width: none;
      flex:none;
    }
    #endeavour-slider-1 {
     // max-height: 300px;
      .slick-slide {
        // max-height: 300px;
      }
      img {
        // width: 100%;
        margin: 0 auto;
        object-fit: cover;
      }
    }
    #endeavour-slider-2 {
      margin: 15px -5px 20px;
      .slick-slide {
        margin: 0 5px;
      }
      .slick-slide img {
        border-radius: .5rem;
        margin:0;
       // margin: 0 10px;//;// 5px;
        border:3px solid white;
        //padding: 0 5px;
       // background-color: white;
      }
      .slick-slide.slick-current img {
        border-color: $endeavour-violet;
      }
    }

  }


}

// body.env-endeavour {
//   @if variable-exists(endeavour-theme-colors) {
//     @each $color, $value in $endeavour-theme-colors {
//       .bg-#{$color} {
//         background-color: $value !important;
//       }
//       .text-#{$color} {
//         color: $value !important;
//       }
//     }
//   }
// }
