// Components

.embed-responsive-rounded {
  border-radius: 0.75rem;
  &:focus {
    outline: 0;
  }
}

.video-mask {
  @include video-mask;
}

// Schools Theming
.shapes {
  position: absolute;
  z-index: 1;
}
.cutout {
  z-index: 2;
}

.play-button {
  border-radius: 50%;
  background-color: $coral;
  border: 0.4rem solid $white;
  width: 6.5rem;
  height: 6.5rem;
  display: flex;
  justify-content: center;
  transition: all 0.3s; //
  cursor: pointer;

  img,
  svg {
    width: 2.3rem;
    margin-left: 10px;
  }

  &:hover,
  &:focus {
    background-color: $aqua;
    animation: pulse 1.5s;
    animation-duration: 1.5s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
  }

  @include media-breakpoint-down(xs) {
    width: 3.5rem;
    height: 3.5rem;
    border-width: 0.25rem;

    img,
    svg {
      width: 1.2rem;
      margin-left: 0.2rem;
    }
  }

}

.play-button-sm {
  width: 3.5rem;
  height: 3.5rem;
  border-width: 0.25rem;

  img,
  svg {
    width: 1.2rem;
    margin-left: 0.2rem;
  }
}

.key-point {
  padding-left: 4.5rem;
  position: relative;

  .icon {
    path {
      fill: $white;
    }

    position: absolute;
    left:0;
    width: 2.6rem;
    position: absolute;
    left: 0.4rem;
    top: -0.15rem;
  }

  &:before {
    content: "";
    left: 0;
    top: -0.6rem;
    background-color: $teal;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    position: absolute;
  }

  a {
    color: $berry;
    font-weight: 600;
  }

  @include media-breakpoint-down(xs) {
    height: auto !important;
  }

}

// End schooll theming


// ul.tick-list-white {
//   @include tick-list(white);
// }

// ul.tick-list-primary {
//   @include tick-list(teal);
// }

// ul.tick-list {
//   @include tick-list(navy);
// }

// ol.numbers-list {
//   @include numbers-list;
// }

.card {
  //border-radius: $border-radius-large;
  border: 2px solid $white;
}


// Navbar
// --------------------------------------------------

.navbar-nav {

  // .dropdown-toggle .caret,
  // .dropdown-toggle:after {
  //   display: none;
  // }

  // .dropdown-menu a.dropdown-item {
  //   text-transform: uppercase;
  //   color: $white;
  //   font-size: $font-size-sm;
  //   font-weight: 600;
  //   padding: 0.25rem 0.75rem;

  //   &:hover,
  //   &:focus {
  //     background: none;
  //     color: $white;
  //     text-decoration: underline;
  //   }
  // }

  // Desktop
  @include media-breakpoint-up(md) {

  //   .dropdown-menu {
  //     min-width: none !important;
  //     display: block;
  //     width: 0;
  //     height: 0;
  //     border: none;
  //     border-radius: 1rem;
  //     background-color: $aqua;
  //     transition: all 0.3s;
  //     opacity: 0;
  //     padding: 0;

  //     a.dropdown-item {
  //       display: none;
  //       padding: 0.25rem 0.75rem;
  //     }
  //   }

  //   .dropdown:hover .dropdown-menu {
  //     //  display: block;
  //     width: auto;
  //     height: auto;
  //     opacity: 1;
  //     padding: 0.5rem;
  //     border: 2px solid $white;
  //     margin-top: -2px; // remove the gap so it doesn't close

  //     a.dropdown-item {
  //       display: block;
  //     }
  //   }
  }
}

.navbar-collapse {
  flex-grow: 0;
}

// Navbar toggle
// --------------------------------------------------
.navbar-toggler {
  cursor: pointer;
  border: none;
  height: 2.5rem;
  position: relative;
  margin: 0 -.625rem 0 0;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  z-index: $zindex-fixed + 1;

  &:focus {
    outline: 0;
  }

  .icon-bar {
    background-color: $white;
  }

  // Bars
  .icon-bar {
    display: block;
    width: 1.8rem;
    height: 3px;
    border-radius: none; // 1px;
    transition: opacity, transform;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    border-radius: 4px;
  }

  .icon-bar+.icon-bar {
    margin-top: 6px;
  }

  &:not(.collapsed) {
    .icon-bar {
      &:nth-child(1) {
        transform: translateY(9px) rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
        transform: translateX(-100%);
      }

      &:nth-child(3) {
        transform: translateY(-9px) rotate(-45deg);
      }
    }
  }
}



// Mobile / Collapsed menu
@include media-breakpoint-down(sm) {
  //@include media-breakpoint-down(md) {

  .navbar-nav .dropdown-menu {
    min-width: none !important;
    display: block;
    background: none;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid $white;

    a.dropdown-item {
      font-size: $font-size-sm * 0.9;
    }
  }

  .offcanvas-collapse {
    position: fixed;
    padding: 2rem 2rem 1rem 1rem;
    bottom: 0;
    right: 0;
    width: 100%;
    overflow-y: auto;
    min-height: 100vh;
    width: 100%;
    background-image:  linear-gradient(215deg, $navy, $navy-dark 60%);
    z-index: $zindex-fixed+2;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
    transform: translateX(100%);
    top: $header-height;
  }

  #header.with-alert .offcanvas-collapse {
    top: $header-height + $header-banner-height;
  }

  .offcanvas-collapse.show,
  .offcanvas-collapse.open {
    transform: translateX(0);
    left: 0;
    overflow: hidden;
  }


}


// Modals
// --------------------------------------------------
.modal-backdrop.show {
  opacity: .7;
  background-color: $navy;
}

.modal button.close {
  opacity: 1;
  text-shadow: none;
  font-weight: 400;
  position: absolute;
  float: none;
  top: -.5rem;
  right: -.5rem;
  color: $white;
  padding: 0;
  overflow: hidden;
  padding: 0.5rem;
  //margin: -1rem -.75rem 0rem auto;

  &:hover,
  &:focus {
    outline: 0;
    opacity: 1 !important;
    text-shadow: 0px 0px 0.75rem $aqua; // $coral;// $yellow;
  }

  span {
    font-size: 4rem;
    display: block;
    line-height: 0.6;
  }
}

.modal-body {
  background-color: $white;
  border-radius: $border-radius-large;
  padding: 2rem;

  .video-wrapper.video-js-responsive,
  .embed-responsive {
    border-radius: 1.25rem;
  }

  .modal-footer {
    border: none;
    padding: 0 1rem 0 0;
  }

  .video-js {
    max-height: 100% !important;
  }
}

.video-modal .modal-body {
  padding: 0.4rem;
}


.modal-content {
  position: relative;
  border-radius: 0; // 1.5rem;
  border: none;
  background-color: transparent;
  padding-top: 2rem;
}

.modal-header {
  padding: 1rem;
  border-bottom: none;
  padding-bottom: 0;

  .modal-title {
    font-size: 1.8rem;
  }
}


// Paragraphs
// --------------------------------------------------

.field-name-field-content-blocks {

  .field-name-field-section-title {
    font-size: $h4-font-size;
    //font-weight: 700;
    font-family: $font-cursive;
    margin-bottom: 1.5rem;
  }

  .paragraphs-item-image-gallery {
    .field-name-field-images {
      display: flex;
      flex-wrap: wrap;
      //justify-content: space-around;
      margin-left: -.375rem;
      margin-right: -.375rem;

      .field-item {
        //flex: 0 0 33%;
        padding: 0 0.75rem;
        margin-bottom: 2rem;

        @include media-breakpoint-up(md) {
          flex: 0 0 33%;
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  .paragraphs-item-images {}
}


// VideoJS
// --------------------------------------------------

.video-wrapper {
  position: relative;
}

.vjs-poster {
  background-color: transparent;
}

div[poster].video {}

.video-js {
  background-color: #ddd;
  width: 100%;
  height: auto;

  .vjs-control {
    min-width: 2.5em;
    max-width: 4em;
  }

  .vjs-progress-control {
    min-width: 4em;
    max-width: none;
  }

  // height: 200px;
  .vjs-big-play-button {
    text-align: center;
    font-size: 3em;
    line-height: 1.5em;
    height: 1.6em;
    width: 2em;
    margin-left: -1em;

    @at-root .view .video-js .vjs-big-play-button {
      font-size: 2em;
    }
  }
}


.video-js-responsive {
  padding: 0;
  padding-bottom: 56.25%;
  width: 100%;
  position: relative;
  display: block;
  overflow: hidden;
}

.video-js-responsive video,
.video-js-responsive .video-js {
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  height: 100% !important;
  width: 100% !important;
  border: 0 !important;
  position: absolute !important;
}

.video-js-responsive.ratio-4by3,
.ratio-4by3 .video-js-responsive {
  padding-bottom: 75%;

  .video-js {
    //height: 100%;
    // width: auto;
  }
}

.video-js .vjs-tech,
.video-js.vjs-fill {
  width: 100% !important;
  height: 100% !important;
}


// Alerts
.alert {
  border-radius: $border-radius-large;
  @include gradient-bg($yellow);
  color: $white;
  border-color: $yellow;
 // max-width: 680px;

  a.close {
    border:none !important;
    opacity: 1 !important;
    text-shadow: none;
    color: $white !important;
    //padding: 0;
  }

  &.alert-dismissible {
    padding-right: 1rem;
  }
}

.alert-success {
  @include gradient-bg($aqua);
  color: $navy-dark;
  border-color: $aqua;
}


// Highcharts
.highcharts-container {
  font-family: $font-family-base;
}

// Slick

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: $berry;//black;//white;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\3031";// !default;
$slick-next-character: "\3031";// !default;
$slick-dot-character: "\2022";// !default;
$slick-dot-size: 24px;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}

/* Slider */
// commented because causing 404s
// .slick-list {
//     .slick-loading & {
//         background: #fff slick-image-url("../images/loader.svg") center center no-repeat;
//     }
// }

/* Icons */
// commented because causing 404s
// @if $slick-font-family == "slick" {
//     // @font-face {
//     //     font-family: "slick";
//     //     src: slick-font-url("slick.eot");
//     //     src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
//     //     font-weight: normal;
//     //     font-style: normal;
//     // }
// }



.slick-track {
  display: flex;
 // align-items: center;
  position: relative;
  top:0;
  left: 0;
}

/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    &:hover, &:focus {
        outline: none;
        background: transparent;
        color: transparent;
        &:before {
            opacity: $slick-opacity-on-hover;
        }
    }
    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
    &:before {
        font-family: $slick-font-family;
        font-size: 24px;
        line-height: 1;
        color: $slick-arrow-color;
        opacity: $slick-opacity-default;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        @include media-breakpoint-up(lg) {
          font-size: 30px;
        }
    }
}

.slick-prev {
  left: -25px;
  [dir="rtl"] & {
      left: auto;
      right: -25px;
  }
  &:before {
      content: $slick-prev-character;
      [dir="rtl"] & {
          content: $slick-next-character;
      }
  }
  @include media-breakpoint-down(sm) {
    left: -45px;
  }
}

.slick-next {
  transform: rotate(180deg);
  right: -25px;
  [dir="rtl"] & {
    left: -25px;
    right: auto;
  }
  &:before {
    content: $slick-next-character;
    [dir="rtl"] & {
      content: $slick-prev-character;
      transform: rotate(180deg);
    }
  }
  @include media-breakpoint-down(sm) {
    right: -45px;
  }

}

/* Dots */
/*
.slick-dotted.slick-slider {
    margin-bottom: 3rem;
}

.slick-dots {
  position: absolute;
  bottom: -2rem;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  justify-content: center;

  li {
    &:before {
      content: "";
    }
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      &:hover, &:focus {
        outline: none;
        &:before {
            opacity: $slick-opacity-on-hover;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: $slick-dot-character;
        width: 20px;
        height: 20px;
        font-family: $slick-font-family;
        font-size: $slick-dot-size;
        line-height: 22px;
        text-align: center;
        color: $slick-dot-color;
        opacity: $slick-opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    &.slick-active button:before {
      color: $slick-dot-color-active;
      opacity: $slick-opacity-default;
    }
  }
}

@include media-breakpoint-up(lg) {
  .slick-dotted.slick-slider {
    margin-bottom: 4rem;
  }
  .slick-dots {
    bottom: -3rem;
  }
} */


// Zigzag
@mixin zigzag($color1: $berry, $color2: $coral, $w: 20px) {
  position: absolute;
  height:100%;
  width: $w/2;
  background:
  linear-gradient(45deg, $color1 25%, transparent 25%) 0 -50px,
  linear-gradient(135deg, $color1 25%, transparent 25%) 0  -50px,
  linear-gradient(225deg, $color1 25%, transparent 25%),
  linear-gradient(315deg, $color1 25%, transparent 25%);
  background-size: $w $w;
  background-color: $color2;
}

.zigzag {
  @include zigzag;
}
