/**
 *
 * Basic styles only - uses CDNs for Bootstrap, etc.
 */
@import "//cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css";
@import "../../node_modules/slick-carousel/slick/slick.scss";
// @import "//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css";

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables"; //BS
@import "../../node_modules/bootstrap/scss/mixins"; //BS

@import "variables";
@import "mixins";

@import "../../node_modules/bootstrap/scss/utilities/spacing"; // Need this SASS include for writing spacing/padding classes in the HTML e.g. mr-1
@import "../../node_modules/bootstrap/scss/mixins/grid"; // Need this SASS include for writing spacing/padding classes in the HTML e.g. mr-1


// New Refresh
@import "base";
@import "components";
@import "typography";
@import "animations";

@import "modules";
@import "layout";
@import "content";
@import "forms";
@import "audience/ripple";
@import "audience/endeavour";



// DIN Font-y Fonts


#header .navbar-nav .nav-item .nav-link,
.layout-above-footer,
.page-about .page-wrapper {
  font-family: $font-din;
}

.page-wrapper .body-content p.lead,
.btn,
.btn-primary,
.btn-lg,
.webform-client-form .form-item:not(.form-type-checkbox) > label:not(.error),
.front .stream-option .btn {
  font-family: $font-din-bold;
  body.page-endeavour & {
    font-family: $font-museo;
  }
  body.ripple-admin & {
    font-family: $font-family-base;
  }
}

  .node-webform-full, .node-page-full {
    .field-name-body p:first-child, .field-name-body p.lead {
      font-family: $font-din;
        body.page-endeavour & {
          font-family: $font-museo;
        }
    }
  }



@include media-breakpoint-up(xl) {
  body {
    font-size: 1.1rem;
  }
  .lead {
    font-size: 1.1em;
  }
}


// iPad
@include media-breakpoint-down(md) {

  // font-size: xxx * $sm_modifier;
  body {
    font-size: 0.9rem; // 1rem * $sm_modifier;
  }

  h1,
  .h1 {
    font-size: $h1-font-size * $sm_modifier;
  }

  h2,
  .h2 {
    font-size: $h2-font-size * $sm_modifier;
  }

  h3,
  .h3 {
    font-size: $h3-font-size * $sm_modifier;
  }

  h4,
  .h4 {
    font-size: $h4-font-size * $sm_modifier;
  }

  h5,
  .h5 {
    font-size: $h5-font-size * $sm_modifier;
  }

  .font-size-xl {
    font-size: $font-size-xl * $sm_modifier;
  }

  .font-size-xxl {
    font-size: $font-size-xxl * $sm_modifier;
  }


}

// Mobile
@include media-breakpoint-down(sm) {

  .lead {
    font-size: $font-size-base;
  }

}





// Schools Theming
/*
@import "base";
@import "schools-theming/components";
@import "schools-theming/typography";
@import "schools-theming/blocks_views";
@import "schools-theming/modules";
@import "schools-theming/marketing";
@import "schools-theming/layout";
@import "schools-theming/forms";
@import "audience/ripple";
@import "audience/endeavour";
@import "schools-theming/animations"; */


