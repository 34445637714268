/**
 *
 * Champion Life
 */


// **** Fonts
$font-family-sans-serif:'Montserrat', Helvetica, Arial, sans-serif;
$font-family-base: $font-family-sans-serif;
//$font-headings: $font-primary;//"Plump", 'Montserrat', Helvetica, Arial, sans-serif;
$font-cursive: 'Kalam', 'Montserrat', cursive;
$font-headings: "DINRoundOT-Bold", 'Montserrat', Helvetica, Arial, sans-serif;
$font-din: "DINRoundOT-Light", 'Montserrat', Helvetica, Arial, sans-serif;
$font-din-bold: $font-headings;
$font-montserrat:'Montserrat', Helvetica, Arial, sans-serif;

// For Endeavour
$font-museo: "Museo Sans Rounded",  'Montserrat', Helvetica, Arial, sans-serif;


// For Ripple
$font-poppins: "Poppins", Helvetica, Arial, sans-serif;
$font-inter:'Inter', Helvetica, Arial, sans-serif;
$font-ripple-headings: $font-poppins;
$font-ripple-body: $font-inter;

// COLOURS
// --------------------------------------------------
$white:           #fff;
$black:           #000;

//9//51e58

// Brand
$aqua: #73cac6;
$teal: #0ba3ad;
$aqua-light: mix($white, $aqua, 40%);

//951e58
$berry: #7b1950;
$yellow: #eca421;
$coral: #ea434f;
$navy: #375868;
$gray: #d5d6da;
$gray-dark: darken($gray, 40%);
$orange: #F58F09;

$navy-dark:    #293e4b;
$coral-dark:   #c72034;
$berry-medium:  #9e1f64;
$yellow-medium: #f2c239;

// Pastels
$aqua-pastel: mix($white, $aqua, 70%);
$berry-pastel: lighten($berry, 67%);
$yellow-pastel: mix($white, $yellow, 88%);
$coral-pastel: mix($white, $coral, 88%);


$red: #ab1c1c; // for errors!

// Primary & Secondary Colours
$primary-color:    $teal;
$secondary-color:  $aqua;
$default-color:    $navy;
$text-color: $navy-dark;

// Put whatever theme colours you want in here!
$theme-colors: ();
$theme-colors: map-merge((
  "primary": $primary-color,
  "secondary": $secondary-color,
  "default": $default-color,
  "white": $white,
  "yellow": $yellow,
  "berry": $berry,
  "coral": $coral,
  "aqua-light": $aqua-light,
  "aqua-pastel": $aqua-pastel,
  "yellow-pastel": $yellow-pastel,
  "berry-pastel": $berry-pastel,
  "coral-pastel": $coral-pastel,
  "navy": $navy,
  "navy-dark": $navy-dark,
  // "orange": $orange,
  "gray": $gray-dark,
  "text": $text-color,
), $theme-colors);

// Button themes
$button-colors: ();
$button-colors: map-merge((
  "yellow": $yellow,
  "berry": $berry,
  "berry-medium": $berry-medium,
  "coral": $coral,
  "info": $gray,
  "aqua": $aqua,
  "navy": $navy,
), $button-colors);


$img-path: '../../dist/images';
$gap: ($grid-gutter-width/2);

// Spacing
$spacer: 1rem !default;
$spacers: ();
$spacers: map-merge((
  0: 0,
  q: ($spacer * .25),
  h: ($spacer * .5),
  1: ($spacer * 1),
  1h: ($spacer * 1.5),
  2: ($spacer * 2),
  2h: ($spacer * 2.5),
  3: ($spacer * 3),
  3h: ($spacer * 3.5),
  4: ($spacer * 4),
  5: ($spacer * 5),
  6: ($spacer * 6),
  7: ($spacer * 7),
  8: ($spacer * 8),
  reset: 15px
), $spacers);

// Grid containers
// Define the maximum width of `.container` for different screen sizes.
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1620px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1550px,
);


$header-height: 70px;
$header-height-desktop: 120px;
$header-banner-height: 30px;
$cap-content: 820px;// 770px;

$checkbox_size: 20px;
$checkbox_size_lg: 22px;


$input-border-radius: 1.5rem;
$border-radius-large: 1.5rem;
$headings-font-family: $font-headings;

$font-weight-semibold: 600;
$font-size-xxl:  $font-size-base * 2;
$font-size-xl:  $font-size-base * 1.5;
$font-size-lg:  $font-size-base * 1.375;
$font-size-sm:  $font-size-base * .875;
$font-size-xs:  $font-size-base * .75;
$font-size-xxs:  $font-size-base * .65;

$lead-font-size: ($font-size-base * 1.05);
$lead-font-weight: 700;


$h1-font-size: $font-size-base * 2.5; // 45px
$h2-font-size: $font-size-base * 2;//1.575;
$h3-font-size: $font-size-base * 1.375;
$h4-font-size: $font-size-base * 1.375;

$font-size-large: $font-size-lg;
$font-size-small: $font-size-sm;

$xxl_modifier: 1.2;
$sm_modifier: 0.875;

$success:       $aqua;
$info:          $yellow;
$warning:       $yellow;
$danger:        $yellow;
