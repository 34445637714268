// Custom module work



// Chosen
.chosen-container {
  border: 0 !important;
  padding: 0 !important;
  height: auto !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  font-size: 0.875rem;
  min-width: 130px;
  display: block;
  width: 100% !important;
}

 
.chosen-search {
  display: none !important;
}

.chosen-container .chosen-drop {
  border: 1px solid $gray-300;
  border-radius: 0.375rem;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  margin: 0;
  color: $text-color;
  padding-top:4px;

}
.chosen-container .chosen-results li:hover,
.chosen-container .chosen-results li.highlighted {
  background-color: $yellow;
  background-image: none;
  color: #fff;
  border-radius: 0.375rem;
}

.chosen-container-multi .chosen-choices {
  border-radius: $input-border-radius;
  border: 2px solid $gray-400;
  background-color: #fff;
  background-image: none;
  cursor: text;
  line-height: 1.5;
  padding: .075rem .75rem;    
  height: calc(1.5em + .75rem + 2px);
}


.chosen-container-multi .chosen-choices li.search-field input[type=text] {
  font-family: inherit;
}

.chosen-container-multi .chosen-choices li.search-choice {
    margin: 3px 5px 3px 0;
    padding: 3px 20px 3px 5px;
    border: none;
    max-width: 100%;
  //  border-radius: 3px;
    background: none;
    background-image: none;
    box-shadow: none;
    color: $text-color;
    line-height: 13px;
    cursor: default;
}

