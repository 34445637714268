/*!
 * animate.css -https://daneden.github.io/animate.css/
 *
 */
@keyframes dash {
  from {
    d: path("M2,2 Q2,8 8,8");
  }
  to {
    d: path("M2,5 A 5 25 -15 0 1 8 8");
  }
}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse2 {
  from {
    transform: scale(1, 1, 1);
  }

  50% {
    transform: scale(1.05, 1.05, 1.05);
  }

  to {
    transform: scale(1, 1, 1);
  }
}


.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}


.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.animated.delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.animated.delay-3s {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated.delay-4s {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.animated.delay-5s {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}

.animated.fast {
  -webkit-animation-duration: 800ms;
  animation-duration: 800ms;
}

.animated.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.animated.slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.slower {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}

@media (print), (prefers-reduced-motion: reduce) {
  .animated {
    -webkit-animation-duration: 1ms !important;
    animation-duration: 1ms !important;
    -webkit-transition-duration: 1ms !important;
    transition-duration: 1ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
  }
}


// Hover.css
// As is often the case, some devices/browsers need additional code to get CSS to work
// in the most desired way. These mixins are used to quickly drop in hacks for each element
// Find out more here: https://github.com/IanLunn/Hover/wiki/Hacks-Explained


// DEFAULT OPTIONS

// Prefix for Hover class names
$nameSpace: 'hvr' !default;

// Browser Prefixes - Which CSS prefixes should be used?
$webkit: true !default;
$moz: false !default;
$ms: false !default;
$o: false !default;

// Compile with or without classes
$includeClasses: true !default;

@mixin hardwareAccel() {
  // Improve performance on mobile/tablet devices
  // Perspective reduces blurryness of text in Chrome
  @include prefixed(transform, perspective(1px) translateZ(0));
}

@mixin improveAntiAlias() {
  // Improve aliasing on mobile/tablet devices
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

@mixin forceBlockLevel() {
  // Transforms need to be block-level to work
  display: inline-block;
  vertical-align: middle;
}

@mixin hacks() {
  @include forceBlockLevel();
  @include hardwareAccel();
  @include improveAntiAlias();
}

// prefix declarations
@mixin prefixed($property, $value) {
  @if $webkit == true {
    -webkit-#{$property}: #{$value};
  }

  @if $moz == true {
    -moz-#{$property}: #{$value};
  }

  @if $ms == true {
    -ms-#{$property}: #{$value};
  }

  @if $o == true {
    -o-#{$property}: #{$value};
  }

  #{$property}: #{$value};
}

// prefix keyframes
@mixin keyframes($name) {
  @if $webkit == true {
    @-webkit-keyframes #{$name} {
      @content;
    }
  }

  @if $moz == true {
    @-moz-keyframes #{$name} {
      @content;
    }
  }

  @if $ms == true {
    @-ms-keyframes #{$name} {
      @content;
    }
  }

  @if $o == true {
    @-o-keyframes #{$name} {
      @content;
    }
  }

  @keyframes #{$name} {
    @content;
  }
}



/* Ripple In */

@keyframes hvr-ripple-in {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}
@mixin hover-ripple($color: white) {
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;

  &:before {
    content: '';
    position: absolute;
    border: $color solid 4px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    animation-duration: 1s;
    @include media-breakpoint-between(sm,lg) {
      border-radius: 10%;
    }
  }
  &:hover {
    border-color: $color;
  }
  &:hover:before, &:focus:before, &:active:before {
    animation-name: hvr-ripple-in;animation-fill-mode: forwards;
    //animation-iteration-count: infinite;
  }
}
