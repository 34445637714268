
// =====================================================================
// Media Query Mixins
// For min/max pixel values


@mixin min($min: 0, $media: "screen") {
  $query: "only " + $media + " and (min-width: " + ($min) + ")";
  @media #{$query} {
    @content;
  };
}


@mixin max($max: 3000, $media: "screen") {
  $query: "only " + $media + " and (max-width: " + ($max - 1) + ")";
  @media #{$query} {
      @content;
  };
}

@mixin minmax($min: null, $max: null, $media: "screen") {
  $query: "only " + $media;

  @if $min != null and $max != null {
      $query: "only " + $media + " and (min-width: " + $min + ") and (max-width: " + ($max - 1) + ")";
  } @else if $min == null and $max != null {
      $query: "only " + $media + " and (max-width: " + ($max - 1) + ")";
  } @else if $min != null and $max == null {
      $query: "only " + $media + " and (min-width: " + $min + ")";
  }

  @media #{$query} {
      @content;
  };
}
@mixin link-underline() {
  position: relative;
  color: mix($primary-color, $default-color);
  font-weight: 500;
 // border-bottom: 2px solid $aqua;
  text-decoration: underline;// none !important;
  &:hover, &:focus {
    // text-decoration: none !important;
    // border-bottom-color: $berry;//$coral;
    color: $gray-dark;
  }
}

@mixin video-mask() {
  .fadeIn {
    @include media-breakpoint-up(md) {
      opacity: 1;
      z-index: 3;
    }
  }

  .fadeOut {
    @include media-breakpoint-up(md) {
      opacity: 0;
      z-index: 2;
    }
  }

  .mask {
    overflow: hidden;
    display: none;
    cursor: pointer;
    transition: all 0.4s;
    @include media-breakpoint-up(md) {
      display: block;
      right: 1rem;
      left: 1rem;
      bottom: 1rem;
    }
  }

  .embed-responsive, img.example {
    margin: 0;
    border-radius: 0.75rem;
    @include media-breakpoint-up(md) {
      margin: 1rem 0;
    }
    &:focus {
      outline: 0;
    }
  }

  .embed-responsive {
    transition: all 0.4s;
  }

  .play-button {
    cursor: pointer;
    position: absolute;
    left: calc(50% - (3.5rem/2));
    top: 40%;
    z-index: 8;
  }
}

@mixin header-dots($color: $default-color, $size: 8px) {
  &:after {
    border-top: $size dotted $color;
    content: "";
    width: 32px;
    margin: 1rem auto;
    display: block;
  }
}
// Terms and conditions
@mixin terms-and-conditions() {
  label {
    cursor: pointer;
    font-family: $font-family-base;
    font-size: 1.1rem;
  }
}

// Tick list
@mixin tick-list($color: white) {
  list-style: none;
  padding:0;
  text-align: left;
  li {
    margin-bottom: 1rem;
    padding-left: 2.375rem;
    position: relative;
    line-height: 1.4;
  }
  li:before {
    line-height: 1.5rem;
    content: "" !important;
    background-image: url('#{$img-path}/icons/tick-circle-#{$color}.svg');
    background-repeat: no-repeat;
    background-size: contain;
    vertical-align: top;
    display: inline-block;
    width: 1.5rem !important;
    height:1.5rem !important;
    position: absolute;
    left:0;
    margin-left: 0 !important;
  }



}
